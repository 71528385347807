
export default {
  name: "top-seller-card",
  props: {
    product: Object,
  },
  methods: {
    goTo() {
      this.$nuxt.$router.push("/menu/5660");
    },
  },
};
