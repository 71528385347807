
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState([ 'order', 'location', 'showCart', 'cart' ]),
    link() {
      if(this.hasCartItems()) {
        return '/checkout/order'
      } else if(this.location && this.cart) {
        return `/menu/${this.location.menu_id}`
      } else {
        return '/'
      }
    },
    count() {
      if (this.cart && this.cart.products && this.cart.products.length > 0) {
        let count = 0
        for (var i = 0; i < this.cart.products.length; i++) {
          if (!this.cart.products[i].name.includes('Utensils')) {
            count+=this.cart.products[i].quantity
          }
        }
        return count
      } else {
        return 0
      }
    },
    isCheckoutPage() {
      return this.$route.fullPath.includes('checkout')
    },
    isCateringPage() {
      return this.$route.fullPath.includes('catering')
    },
    hasCartItems() {
      return this.cart?.products?.length > 0
    }
  },
  methods: {
    ...mapActions(['viewCart', 'hideCart']),
    toggle() {
      if (this.isCheckoutPage) return

      if (!this.location) {
        this.$router.push('/menu/5660')

      //   if (this.isCateringPage) {
      //     this.$router.push('/catering')
      //     return
      //   }

      //   this.$router.push('/locations')
      //   return
      }

      if (this.showCart) {
        document.body.style.overflow = 'auto'
      } else {
        document.body.style.overflow = 'hidden'
      }
      if(!this.showCart && this.cart) {
        this.viewCart(this.cart);
      } else {
        this.hideCart();
      }
    }
  }
}
