
import { mapState } from 'vuex'

export default {
  name: 'NavLink',
  props: {
    link: {
      type: Object,
      default: {
        to: '/',
        title: 'Home'
      }
    }
  },
  computed: {
    ...mapState([ 'cart', 'user', 'location' ]),
    to() {
      if(this.link.to.match(/^\/menu/)) {
        return '/menu/5660'
      } else if(this.link.to.match(/^\/order/)) {
        if(this.cart) {
          if(this.cart.products.length > 0) {
            if(this.user) {
              return `/order/checkout`
            } else {
              return `/order/account`
            }
          } else {
            return `/menu/${this.cart.menu_id}`
          }
        } else {
          return `/menu/5660`
        }
      } else if(this.link.to.match(/^\/account/)) {
        if(this.user) {
          return '/account'
        } else {
          return '/account/signin'
        }
      } else if(this.link.to.match(/^\/rewards/)) {
        if(this.user) {
          return `/account${this.link.params}`
        } else {
          return this.link.to
        }
      } else {
        return this.link.to
      }
    },
    title() {
      // if(this.link.to.match(/^\/account/)) {
      //   if(this.user) {
      //     return this.link.title.split('|')[1].replace(/\%first_name%/, this.user.first_name)
      //   } else {
      //     return this.link.title.split('|')[0]
      //   }
      // } else 
      if(this.link.to.match(/^\/order/)) {
        return `${this.link.title}${this.cart && this.cart.products.length > 0 ? ` (${this.cart.products.length})` : ''}`
      } else {
        return this.link.title
      }
    }
  },
  methods: {
    toggleNav(ev) {
      console.log(ev)
      document.body.style.overflow = this.showNav ? 'hidden' : 'auto'
      this.$store.commit('setShowNav', false)
    }
  }
}
